import { Dispatch, SetStateAction, useMemo, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { queryMembership } from "@RHCommerceDev/graphql-client/queries/user";
import { removeMembershipMutation } from "@RHCommerceDev/graphql-client-cart-broker/queries/remove-membership";
import { restoreMembershipMutation } from "@RHCommerceDev/graphql-client/queries/membership";
import { addMembershipMutation } from "@RHCommerceDev/graphql-client-cart-broker/queries/add-membership";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import useErrorDialog from "@RHCommerceDev/hooks/useErrorDialog";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { countries } from "@RHCommerceDev/resources/countries-config.json";
import yn from "yn";
import {
  useUserSessionAtomValue,
  useRhUserAtomValue
} from "@RHCommerceDev/hooks-atoms";
import { useGetCartProjectLazyQuery } from "@RHCommerceDev/hooks-queries";
import { membershipHandler } from "@RHCommerceDev/utils/error-handlers/membership-handler";
import { UserPreferencesContext } from "customProviders/UserPreferencesProvider";
import { useCurrentCartId } from "@RHCommerceDev/hooks-atoms/useCurrentCartId";

export interface UseMembershipOptions {
  skip?: boolean;
  onCompleted?: (data: MembershipType | null) => void;
}

export interface UseMembershipMutaionOption {
  membership?: MembershipType | null;
  onRestoreMembershipCompleted?: (
    data: Pick<Mutation, "restoreMembership">
  ) => void;
  cartType?: boolean;
  onJoinMembership?: () => void;
  removeMembershipHandler?: () => void;
  onLoading?: Dispatch<SetStateAction<Boolean>>;
  isAutoMembershipAdd?: boolean;
  refetchGetCart?: () => void;
  setMembershipLoading?: Dispatch<SetStateAction<Boolean>>;
}

export function useMembership({
  skip,
  onCompleted
}: UseMembershipOptions = {}) {
  const { loading: loadingSession } = useUserSessionAtomValue();
  const { id: userId, userType } = useRhUserAtomValue();

  const country = useCountry();
  const { loading, error, data, refetch } = useQuery<
    Pick<Query, "membership">,
    QueryMembershipArgs
  >(queryMembership, {
    variables: {
      userId, // UserV3
      region: countries?.[country]?.region
    },
    skip: skip || userType === "ANONYMOUS" || loadingSession || !userId,
    notifyOnNetworkStatusChange: true,
    onError: () => {},
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (onCompleted && !data?.membership?.errorMessage) {
        onCompleted(data?.membership);
      }
    }
  });
  const membership = useMemo(() => {
    if (data?.membership?.errorMessage) {
      return null;
    } else {
      return data?.membership;
    }
  }, []);
  return { loading, membership, error, refetch };
}

export function useMembershipMutation({
  membership,
  onRestoreMembershipCompleted,
  cartType,
  onJoinMembership,
  removeMembershipHandler,
  onLoading,
  isAutoMembershipAdd = false,
  refetchGetCart,
  setMembershipLoading
}: UseMembershipMutaionOption = {}) {
  const env = useEnv();
  const history = useHistory();
  const country = useCountry();
  const prefix = useLocalization();
  const { setCart } = useContext(UserPreferencesContext);
  const { rhuid, rhUser } = useUserSessionAtomValue();
  const cartId = useCurrentCartId();
  const [getCartProjection] = useGetCartProjectLazyQuery();
  const { showError } = useErrorDialog();
  const pc = useIsoCookies(["pc"], true)?.pc;
  const variables = {
    email: rhUser?.email,
    cartId,
    ...(yn(env.FEATURE_ADD_ITEM_CREATE_CART)
      ? {
          createCartInfo: {
            guest: {
              email: rhUser?.email || "",
              userId: rhUser?.id
            },
            postalCode: pc,
            country
          }
        }
      : null)
  };

  const [restoreMembership, { loading: restoreMembershipLoading }] =
    useMutation<
      Pick<Mutation, "restoreMembership">,
      MutationRestoreMembershipArgs
    >(restoreMembershipMutation, {
      variables: {
        userId: rhUser?.id,
        contactId: rhUser?.sfContactId,
        region: countries?.[country]?.region,
        membershipId: membership?.membershipId,
        membershipUuid: rhuid
      },
      onError: error => {
        onLoading?.(false);
        showError(error);
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onCompleted: async data => {
        if (onRestoreMembershipCompleted) {
          onRestoreMembershipCompleted(data);
        }
        onLoading?.(false);
      }
    });

  /* add membership to cart query */
  const [addMembershipItemsToCart, { loading: addMembershipLoading }] =
    useMutation<Mutation>(addMembershipMutation, {
      variables,
      onCompleted: async ({ addMembership }) => {
        if (addMembership) {
          setCart(addMembership);
        }

        /* refetch cart projection */
        getCartProjection();
        setMembershipLoading?.(false);
        onLoading?.(false);
        if (cartType) {
          /* if become a membership dialog */
          if (onJoinMembership) {
            onJoinMembership();
          }
        } else if (!isAutoMembershipAdd) {
          /* push to cart page if added from account */
          history.push(`${prefix}/checkout/shopping_cart.jsp`);
        }
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: error => {
        setMembershipLoading?.(false);
        onLoading?.(false);
        showError(error, membershipHandler);
      }
    });

  /* remove membership query */
  const [removeMembership, { loading: removeMembershipLoading }] = useMutation(
    removeMembershipMutation,
    {
      onCompleted: async ({ removeMembership }) => {
        if (removeMembership) {
          setCart(removeMembership);
        }
        getCartProjection();
        if (removeMembershipHandler) {
          removeMembershipHandler();
        }
        setMembershipLoading?.(false);
        if (onLoading) {
          onLoading(false);
        }
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: error => {
        setMembershipLoading?.(false);
        onLoading?.(false);
        showError(error);
      }
    }
  );

  return {
    removeMembership,
    removeMembershipLoading,
    addMembershipItemsToCart,
    addMembershipLoading,
    restoreMembership,
    restoreMembershipLoading
  };
}
