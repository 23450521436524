import { SvgIconProps } from "@mui/material/SvgIcon";
import React, { FC } from "react";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";

export interface RHRSearchIconProps extends SvgIconProps {
  screenSize?: boolean;
}

export const RHRSearchIcon: FC<RHRSearchIconProps> = ({ ...props }) => {
  const { isConcierge } = useAppId();
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        style={props.style}
        className={isConcierge
          ? "hidden sm:block h-[15px] stroke-current stroke-[0.75px]"
          : "hidden sm:block w-4 h-4 sm:w-6 sm:h-6 stroke-current stroke-[0.75px]"
        }
      >
        <circle cx="11" cy="11" r="10.625" />
        <line x1="18.2652" y1="18.7348" x2="23.2652" y2="23.7348" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${props?.screenSize ? 16 : 24}`}
        height={`${props?.screenSize ? 16 : 24}`}
        viewBox="0 0 24 24"
        fill="none"
        className="sm:hidden"
        style={props.style}
      >
        <circle
          cx="11.9987"
          cy="11.3333"
          r="7.08333"
          stroke="currentColor"
          stroke-width="0.5"
        />
        <line
          x1="16.8421"
          y1="16.4899"
          x2="20.1755"
          y2="19.8232"
          stroke="currentColor"
          stroke-width="0.5"
        />
      </svg>
    </>
  );
};

export default RHRSearchIcon;
