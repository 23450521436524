import React, { FC } from "react";
import classNames from "classnames";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { processEnvServer } from "hooks/useSsrHooks";
import { getReqContext } from "utils/reqContext";

const env = useEnv();
const isTailwindIconEnabled = yn(env.FEATURE_TAILWIND_ICON);
export const RHDropdownIcon: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 12,
    ...props.style
  };
  const req = getReqContext();
  const isCartPage =
    (!processEnvServer
      ? location.pathname?.includes("/checkout/shopping_cart.jsp")
      : req?.path?.includes("/checkout/shopping_cart.jsp")) || false;
  return (
    <>
      {isTailwindIconEnabled ? (
        <>
          {isCartPage ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              {...props}
              style={style}
              className={classNames([props.className, "rotate-90"])}
            >
              <path d="M5 2L11 8L5 14" stroke="black" stroke-width="0.75" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              {...props}
              style={style}
              className={classNames([props.className, "rotate-90"])}
            >
              <path d="M5 2L11 8L5 14" stroke="black" stroke-width="0.75" />
            </svg>
          )}
        </>
      ) : (
        <SvgIcon
          viewBox="0 0 12 7"
          {...props}
          style={style}
          className={classNames([props.className])}
        >
          {isCartPage ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="4"
              viewBox="0 0 7 4"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.80389 3.872L6.87425 0.757334C7.04192 0.576 7.04192 0.298667 6.87425 0.128C6.70659 -0.0426664 6.42365 -0.0426664 6.25599 0.128L3.5 2.93333L0.744012 0.128C0.565868 -0.0426666 0.293413 -0.0426666 0.125748 0.128C-0.0419162 0.298667 -0.0419162 0.576 0.125748 0.757333L3.18563 3.872C3.36377 4.04267 3.63623 4.04267 3.80389 3.872Z"
                fill="black"
              />
            </svg>
          ) : (
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Form/Dropdown/Selected-Mobile"
                transform="translate(-312.000000, -25.000000)"
              >
                <rect
                  id="Rectangle-2"
                  stroke="#E9E9E9"
                  fill="none"
                  x="0.5"
                  y="6.5"
                  width="342"
                  height="43"
                ></rect>
                <g
                  id="Grommet/Arrow"
                  transform="translate(318.000000, 28.500000) rotate(-270.000000) translate(-318.000000, -28.500000) translate(315.000000, 23.000000)"
                  fill="currentColor"
                  fillRule="nonzero"
                >
                  <path
                    d="M5.90746133,5.28071462 L0.54926834,0.0916330487 C0.423109198,-0.0305443496 0.220778499,-0.0305443496 0.0946193565,0.0916330487 C-0.0315397855,0.213810447 -0.0315397855,0.409755331 0.0946193565,0.531932729 L5.22429768,5.49971185 L0.0946193565,10.467491 C-0.0315397855,10.5896684 -0.0315397855,10.7856132 0.0946193565,10.9077906 C0.156508747,10.9677267 0.239821388,11 0.320753668,11 C0.401685947,11 0.484998588,10.970032 0.546887979,10.9077906 L5.90508097,5.71870907 C6.03124012,5.5988369 6.03124012,5.40058679 5.90746133,5.28071462 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          )}
        </SvgIcon>
      )}
    </>
  );
};

export default RHDropdownIcon;
