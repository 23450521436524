import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

export const RHChevronIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      {...(props ?? {})}
      style={props?.style}
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clip-path="url(#clip0_5973_43782)">
        <path
          d="M7.35939 2.8253C7.44035 2.90626 7.44035 3.03734 7.35939 3.1183L3.40781 7.06987L7.35939 11.0214C7.44035 11.1024 7.44035 11.2335 7.35939 11.3144C7.27843 11.3954 7.14735 11.3954 7.06639 11.3144L2.96832 7.21637C2.88736 7.13541 2.88736 7.00433 2.96832 6.92337L7.07025 2.82145C7.15121 2.74049 7.28228 2.74049 7.36324 2.82145L7.35939 2.8253Z"
          fill={props?.htmlColor || "white"}
          stroke={props?.htmlColor || "white"}
          stroke-width="0.25"
        />
        <path
          d="M11.4575 6.92433C11.4961 6.96288 11.5153 7.013 11.5192 7.07082C11.5192 7.18648 11.4305 7.27515 11.3149 7.27515L3.11873 7.27515C3.00307 7.27515 2.9144 7.18648 2.9144 7.07082C2.9144 6.95517 3.00307 6.8665 3.11873 6.8665L11.3149 6.8665C11.3688 6.8665 11.4228 6.88963 11.4614 6.92818L11.4575 6.92433Z"
          fill={props?.htmlColor || "white"}
          stroke={props?.htmlColor || "white"}
          stroke-width="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_5973_43782">
          <rect
            width="10"
            height="10"
            fill={props?.htmlColor || "white"}
            transform="translate(7.07129 14.1426) rotate(-135)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default RHChevronIcon;
