import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import useBrand from "hooks-use-brand/useBrand";

export const RHZoomInIcon: FC<SvgIconProps> = props => {
  const brandCode = useBrand();
  const style = {
    fontSize: 25,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 25 25" {...props} style={style}>
      <title>Grommet/ZoomIn</title>
      <desc>Created with Sketch.</desc>
      <g id="PDP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Grommet/ZoomIn" transform="translate(0.000000, 0.500000)">
          <path
            d="M25,0 L25,25 L0,25 L0,0 L25,0 Z M13,6 L12,6 L12,11.999 L6,12 L6,13 L12,12.999 L12,19 L13,19 L13,13 L19,13 L19,12 L13,12 L13,6 Z"
            id="Combined-Shape"
            fill={brandCode === "MO" ? "#E6E6E6" : "#38352F"}
            fillRule="evenodd"
            opacity={brandCode === "MO" ? "0.5" : "0.7"}
          ></path>
          <path
            d="M12.5,6 L12.5,19 M6,12.5 L19,12.5"
            id="Combined-Shape"
            stroke={brandCode === "MO" ? "#000000" : "#FFFFFF"}
            strokeWidth="1"
            opacity={brandCode === "MO" ? "0.7" : "0.4"}
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHZoomInIcon;
