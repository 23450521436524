import React, { FC } from "react";

import { SvgIconProps } from "@mui/material/SvgIcon";

export const LargeArrowIconV2: FC<SvgIconProps> = props => {
  const style = {
    ...props.style
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83128 0.195287C6.05626 0.455651 6.05624 0.877761 5.83123 1.1381L1.62956 5.99948L5.83128 10.862C6.05626 11.1223 6.05624 11.5444 5.83123 11.8048C5.60622 12.0651 5.24144 12.0651 5.01645 11.8047L6.91433e-07 5.99938L5.0165 0.195237C5.2415 -0.0650987 5.60629 -0.0650761 5.83128 0.195287Z"
        fill="black"
      />
    </svg>
  );
};

export default LargeArrowIconV2;
