import React, { FC } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

interface LargeArrowIconProps extends SvgIconProps {
  col?: number;
}

const useStyles = col =>
  makeStyles(() =>
    createStyles({
      root: {
        width: featureCgCarousel && col === 4 ? "16px" : "32px",
        height: featureCgCarousel && col === 4 ? "48px" : "56px"
      }
    })
  );

const env = useEnv();
const featureCgCarousel = yn(env?.FEATURE_CG_CAROUSEL);
const isTailwindIconEnabled = yn(env?.FEATURE_TAILWIND_ICON);
export const LargeArrowIcon: FC<LargeArrowIconProps> = ({
  stroke,
  style = {},
  fill = "white",
  col,
  ...props
}) => {
  const classes = useStyles(col)();
  const iconWidth = featureCgCarousel && col === 4 ? "16px" : "32px";
  const iconHeight = featureCgCarousel && col === 4 ? "48px" : "56px";
  const pathD =
    featureCgCarousel && col === 4 ? "M12 32L4 24L12 16" : "M22 40L10 28L22 16";

  return isTailwindIconEnabled ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      className={classNames(props?.className)}
      style={{ ...style }}
    >
      <path d="M6 1L18 13L6 25" stroke={stroke ?? "black"} stroke-width="1.5" />
    </svg>
  ) : (
    <SvgIcon
      viewBox={`0 0 ${iconWidth} ${iconHeight}`}
      {...props}
      className={classNames(classes?.root, props?.className)}
      style={{ fill: "none", ...style }}
    >
      <rect
        width={iconWidth}
        height={iconHeight}
        fill={fill}
        fill-opacity="0.5"
      />
      <path d={pathD} stroke={stroke ?? "black"} stroke-width="1.5" />
    </SvgIcon>
  );
};

export default LargeArrowIcon;
