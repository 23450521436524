import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

export const RHGalleryLocatorIcon: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 17,
    ...props.style,
    marginRight: 10
  };

  return (
    <SvgIcon viewBox="0 0 12 17" {...props} style={style}>
      <g
        id="Final"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Footer" transform="translate(-226.000000, -77.000000)">
          <rect
            id="Rectangle"
            fill="transparent"
            x="191"
            y="61"
            width="81"
            height="81"
          ></rect>
          <g
            id="Grommet/GPS"
            transform="translate(219.000000, 73.000000)"
            stroke="currentColor"
          >
            <g id="Group" transform="translate(8.000000, 5.000000)">
              <path
                d="M4.99997289,0 C2.24269271,0 0,2.16315834 0,4.82137279 C0,7.37810831 4.58297865,15.2836874 4.77821936,15.6189704 L4.99997289,16 L5.22205174,15.6189704 C5.41696713,15.283426 10,7.37810831 10,4.82137279 C10,2.16315834 7.75671089,0 4.99997289,0 Z"
                id="Path"
                strokeWidth="0.7"
                fillRule="nonzero"
              ></path>
              <path
                d="M4.99968346,7 C3.89719572,7 3,6.10292637 3,5.00017265 C3,3.89713118 3.89713817,3 4.99968346,3 C6.10280428,3 7,3.89707363 7,5.00017265 C7,6.10292637 6.10251651,7 4.99968346,7 Z"
                id="Path"
                strokeWidth="0.7"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHGalleryLocatorIcon;
