import { LazyQueryHookOptions, useApolloClient } from "@apollo/client";
import { queryGetUserForSession } from "@RHCommerceDev/graphql-client/queries/session";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { REQUIRED_PERMISSION_COUNTRIES } from "@RHCommerceDev/utils/constants";
import {
  useUserSessionAtomValue,
  useUserSessionSetAtom
} from "@RHCommerceDev/hooks/atoms";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useCallback, useMemo, useState } from "react";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { isValidPostalCode as getIsValidPostalCode } from "@RHCommerceDev/utils/postalcode-validation";
import type { CountryCode } from "@RHCommerceDev/hooks/useCountry";
import { useCookiePermissionBannerSetAtom } from "@RHCommerceDev/hooks/atoms/useCookiePermissionBannerAtom";
import { useIsCheckout } from "@RHCommerceDev/hooks/useIsCheckout/useIsCheckout";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { v4 as uuidv4 } from "uuid";
import { useSetCurrentCartId } from "@RHCommerceDev/hooks-atoms/useCurrentCartId";

export const useUserSessionLazyQuery = (options?: LazyQueryHookOptions) => {
  const client = useApolloClient();

  const { cookiePermissionCountry, setCookiePermissionCountry } =
    useCookiesWithPermission();

  const setPermissionCountry = useCookiePermissionBannerSetAtom();

  const { app, setApp } = useAppData();

  const setSessionAtom = useUserSessionSetAtom();
  const setCurrentCartId = useSetCurrentCartId();
  const prevSession = useUserSessionAtomValue();

  const country = getCountryFromUrl();

  const { pc: postalCode = "" } = useIsoCookies(["pc"], true);

  const [isLoading, setIsLoading] = useState(false);

  const isValidPostalCode = useMemo(
    () => getIsValidPostalCode(postalCode, country as CountryCode),
    [country, postalCode]
  );

  const { isCheckout } = useIsCheckout();
  const env = useEnv();

  const getUserSessionQueryWrapper = useCallback(async () => {
    try {
      setIsLoading(true);
      const lastRequestId = uuidv4();
      setSessionAtom(prev => {
        return {
          ...prev,
          ...prevSession,
          lastRequestId,
          loading: true,
          loadingUpdateUserSession: prev?.loadingUpdateUserSession,
          prevLoadingCall: (prev?.prevLoadingCall || 0) + 1,
          isCartMerge: prev?.isCartMerge
        };
      });

      const { data: { getUserForSession } = {} } = await client?.query({
        query: queryGetUserForSession,
        variables: {
          useCartBroker: true,
          postalCode: isValidPostalCode ? postalCode : "",
          country,
          /** prevent automatic cart creation. carts should be created at the time when an item or membership is added */
          createCart: !yn(env.FEATURE_ADD_ITEM_CREATE_CART),
          /** we want to avoid updating the postal code or country of the cart while we are in the checkout flow... causes address drops */
          doNotMutateCart: isCheckout,
          // queryTimeStamp is added to ensure to the data is not coming from disk cache
          queryTimeStamp: String(Date.now()),
          skipMembershipInfo: yn(env.FEATURE_PERFORMANCE_SESSION_MEMBERSHIP)
        }
      });

      if (getUserForSession?.rhUser?.postalCode) {
        setApp({ ...app, postalCode: getUserForSession.rhUser?.postalCode });
      }

      if (!cookiePermissionCountry) {
        if (
          REQUIRED_PERMISSION_COUNTRIES?.includes(
            getUserForSession?.rhUser?.akamaiCountryCode || ""
          )
        ) {
          setCookiePermissionCountry(
            getUserForSession?.rhUser?.akamaiCountryCode || ""
          );
        } else {
          setPermissionCountry(
            (getUserForSession?.cookiePreferences?.cookieRules as string) || ""
          );
        }
      }

      const currentCartId =
        getUserForSession?.currentCartId || prevSession?.currentCartId;
      setCurrentCartId(getUserForSession?.currentCartId);
      setSessionAtom(prev => {
        const previousCall = Math.max(
          0,
          (Number(prev?.prevLoadingCall) || 0) - 1
        );
        return {
          ...prev,
          ...getUserForSession,
          prevLoadingCall: previousCall,
          loading: previousCall === 0 ? false : true,
          lastRequestIdToCompare: lastRequestId,
          loadingUpdateUserSession: prev?.loadingUpdateUserSession
        };
      });

      if (
        currentCartId &&
        !prevSession?.anonCartId &&
        (getUserForSession?.rhUser?.userType === "GUEST" ||
          getUserForSession?.rhUser?.userType === "ANONYMOUS")
      ) {
        setSessionAtom(prevData => ({
          ...prevData,
          anonCartId: currentCartId
        }));
      }

      await options?.onCompleted?.({ getUserForSession });
    } catch (err) {
      console?.error(err);
      setSessionAtom(prev => {
        return {
          ...prev,
          loading: false,
          prevLoadingCall: 0
        };
      });
    } finally {
      setIsLoading(false);
    }
  }, [postalCode, country, isValidPostalCode, prevSession]);

  return [getUserSessionQueryWrapper, { isLoading }] as const;
};

export default useUserSessionLazyQuery;
