import { Theme, useMediaQuery } from "@mui/material";
import React from "react";
import props from "theme/props";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";
import { processEnvServer } from "hooks/useSsrHooks";

export interface RHHamburgerProps {
  color?: string;
}

const RHHamburger: React.FC<RHHamburgerProps> = ({ color }) => {
  const req = getReqContext();
  let mobile = false;
  const userAgentSSR = req && req?.headers["user-agent"];
  if (userAgentSSR) {
    const { isMobile } = getSelectorsByUserAgent(userAgentSSR);
    mobile = !!isMobile;
  }
  const desktop = useMediaQuery<Theme>(theme => theme.breakpoints.up(660));
  const size = !processEnvServer
    ? desktop
      ? "24px"
      : "16px"
    : !mobile
    ? "24px"
    : "16px";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      {...props}
    >
      {(!processEnvServer ? desktop : !mobile) ? (
        <g stroke={color} strokeWidth={0.75}>
          <path d="M1 .625h22M1 11.625h22M1 22.625h22" />
        </g>
      ) : (
        <path
          stroke={color}
          strokeWidth={0.5}
          d="M.5.75h15M.5 8.084h15M.5 15.416h15"
        />
      )}
    </svg>
  );
};
export default RHHamburger;
