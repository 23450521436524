import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";

export const RHCloseIconUpdated: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      style={{ cursor: "pointer", ...props.style }}
      {...props}
      className={classNames([
        `${!props.className ? "!" : ""}text-[20px]`,
        props.className
      ])}
    >
      <title>Close Icon</title>
      <desc>Updated close icon with diagonal lines</desc>
      <path
        d="M1.0481 0.902832L19.2419 19.0966"
        stroke="currentColor"
        strokeWidth="1.13711"
      />
      <path
        d="M1.0481 19.0967L19.2419 0.902896"
        stroke="currentColor"
        strokeWidth="1.13711"
      />
    </SvgIcon>
  );
};

export default RHCloseIconUpdated;
