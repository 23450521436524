import { setStorageValue } from "hooks/useCookiesWithPermission";
import { useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { useMemo } from "react";
import { LocalSessionStorageKeys } from "utils/cookies-permission";

const storage = createJSONStorage<string>(() => ({
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (storageKey: LocalSessionStorageKeys, value) => {
    setStorageValue({ storageKey, value });
  },
  removeItem: key => localStorage.removeItem(key)
}));
export const currentCartIdAtom = atomWithStorage<string>("cartId", "", storage);
export const useCurrentCartId = () => {
  const currentCartId = useAtomValue(currentCartIdAtom);
  return useMemo(() => currentCartId, [currentCartId]);
};
export const useSetCurrentCartId = () => useSetAtom(currentCartIdAtom);

export default useCurrentCartId;
