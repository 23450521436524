import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useNewURLStructureParams } from "@RHCommerceDev/hooks/useParams";
import buildPath from "@RHCommerceDev/utils/buildPath";
import yn from "yn";
import useParams from "@RHCommerceDev/hooks/useParams";
import useSite from "hooks/useSite";
import { BCT_PATHS } from "utils/constants";
import { useAppId } from "hooks-use-app-id";

const useGetPDPRedirectPath = (data, isSale = false) => {
  const env = useEnv();
  const { isConcierge } = useAppId();
  const params = useParams({
    fullSkuId: "",
    sale: "",
    swatch: ""
  });
  const { category } = useNewURLStructureParams();
  const formattedProductName = data?.displayName
    ?.toLowerCase()
    .replace(/\s+/g, "-");
  const isNewURLFeatureEnabled = yn(env.FEATURE_URL_CHANGE);
  const showSale = !(
    Number(data?.saleInfo?.percentSaleSkus) === 0 ||
    Number(data?.saleInfo?.percentSaleSkus) === 100
  );
  const siteId = useSite();
  const bctPath =
    yn(env?.FEATURE_BCT_SUNSET) && !isConcierge ? BCT_PATHS[siteId] || "" : "";

  let queryParams = "";
  if (showSale && isSale) {
    queryParams = `?sale=${showSale}`;
  }

  if (isNewURLFeatureEnabled && category) {
    return buildPath(
      `${bctPath}/${category}/pdp/${formattedProductName}${queryParams}`
    );
  } else {
    return buildPath(
      `${bctPath}/catalog/product/product.jsp/${data?.id}${queryParams}`
    );
  }
};

export default useGetPDPRedirectPath;
