import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";
import { getGlobal } from "utils/Globals/getGlobal";
import yn from "yn";

const { FEATURE_STYTCH } = getGlobal("_env");

export const RHCloseIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      style={{
        cursor: "pointer",
        ...(yn(FEATURE_STYTCH) && {
          width: 16,
          height: 16
        }),
        ...props.style
      }}
      {...props}
      className={classNames([
        `${!props.className ? "!" : ""}text-[20px]`,
        props.className
      ])}
    >
      <title>Combined Shape</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Grommet/X-Close" transform="translate(-15.000000, -15.000000)">
          <rect id="Rectangle" x="0" y="0" width="50" height="50"></rect>
          <path
            d="M34.3548387,15 L35,15.6451613 L25.645,24.999 L35,34.3548387 L34.3548387,35 L25,25.645 L15.6451613,35 L15,34.3548387 L24.354,25 L15,15.6451613 L15.6451613,15 L25,24.354 L34.3548387,15 Z"
            id="Combined-Shape"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHCloseIcon;
