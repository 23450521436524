import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { Brand } from "@RHCommerceDev/types";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import { getClientOrigin } from "@RHCommerceDev/utils/getClientOrigin";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import Cookies from "universal-cookie";
import { SELECTED_BRAND_COOKIE } from "@RHCommerceDev/utils/constants";

const env = getGlobal("_env");

export const DOMAIN_RH_CORE = env.ENV_DOMAIN;

export enum BRAND_CODE {
  CORE = "RH",
  MODERN = "MO",
  BABYCHILD = "BC",
  TEEN = "TN",
  SKIHOUSE = "SH",
  BEACHHOUSE = "BH",
  OUTDOOR = "OD",
  INTERIORS = "IN",
  CONTEMPORARY = "CN",
  WATERWORKS = "WW"
}

export const BRAND_NAME_INTERIORS = "INT";
export const BRAND_NAME_OUTDOOR = "OD";

export enum SUBDOMAIN {
  "BH" = `rhbeachhouse`,
  "SH" = `rhskihouse`,
  "TN" = `rhteen`,
  "BC" = `rhbabyandchild`,
  "OD" = "rhoutdoor",
  "MO" = `rhmodern`,
  "IN" = `rhinteriors`,
  "CN" = `rhcontemporary`
}

declare global {
  interface Window {
    brandOnAEM: Brand;
    isAEMAuthorMode: false;
  }
}

export const brandMapTarget = {
  [DOMAIN_RH_CORE]: BRAND_CODE.CORE,
  [SUBDOMAIN.MO]: BRAND_CODE.MODERN,
  [SUBDOMAIN.BC]: BRAND_CODE.BABYCHILD,
  [SUBDOMAIN.TN]: BRAND_CODE.TEEN,
  [SUBDOMAIN.SH]: BRAND_CODE.SKIHOUSE,
  [SUBDOMAIN.BH]: BRAND_CODE.BEACHHOUSE,
  [SUBDOMAIN.OD]: BRAND_CODE.OUTDOOR,
  [SUBDOMAIN.IN]: BRAND_CODE.INTERIORS,
  [SUBDOMAIN.CN]: BRAND_CODE.CONTEMPORARY
};

export const brandMapHandler = {
  get: (target, name: string) => target[name] || target[DOMAIN_RH_CORE]
};

export const brandMap = new Proxy(brandMapTarget, brandMapHandler);

export const brandCodeToHTMLTitle: { [key: string]: string } = {
  [BRAND_CODE.CORE]: "RH",
  [BRAND_CODE.MODERN]: "RH Modern",
  [BRAND_CODE.BABYCHILD]: "RH Baby & Child",
  [BRAND_CODE.TEEN]: "RH TEEN",
  [BRAND_CODE.SKIHOUSE]: "RH SkiHouse",
  [BRAND_CODE.BEACHHOUSE]: "RH BeachHouse",
  [BRAND_CODE.OUTDOOR]: "RH Outdoor",
  [BRAND_CODE.INTERIORS]: "RH Interiors",
  [BRAND_CODE.CONTEMPORARY]: "RH Contemporary",
  [BRAND_CODE.WATERWORKS]: "RH Waterworks"
};

export const getBrand = (
  hostname: string = window.location.hostname,
  defaultBrandMapHandler: ProxyHandler<typeof brandMapTarget> = brandMapHandler
): Brand => {
  const isConcierge = env?.APP_ID === "CONCIERGE_UI";
  const req = getReqContext();
  const path = !processEnvServer ? window.location.pathname : req?.path;
  const prefix = path?.split("/")?.slice(0, 3)?.join("/");

  if (isConcierge) {
    const req: { cookies?: any } = getReqContext() ?? {};
    const conciergeCookie = new Cookies(req.cookies);
    const savedBrand = conciergeCookie.get(SELECTED_BRAND_COOKIE);
    return savedBrand ? (savedBrand as Brand) : ("RH" as Brand);
  }

  const hostServer = getClientOrigin();
  const [subDomain] = processEnvServer
    ? hostServer?.split(".") || ""
    : hostname?.split(".");

  const brandMap = new Proxy(brandMapTarget, defaultBrandMapHandler);
  const getBrandFromUrl = () => {
    if (path?.includes(`${prefix}/babyandchild`)) return "BC";
    else if (path?.includes(`${prefix}/teen`)) return "TN";
    return brandMap[subDomain];
  };

  const brand = getBrandFromUrl();
  return (env?.FEATURE_BCT_SUNSET ? brand : brandMap[subDomain]) as Brand;
};

//-----TODO: remove reliance on this context map after BCC updates links to point towards proper site subdomains-----
export const brandContextMap: { [key in BRAND_CODE]?: string } = {
  [BRAND_CODE.SKIHOUSE]: "SkiHouse",
  [BRAND_CODE.BEACHHOUSE]: "BeachHouse",
  [BRAND_CODE.OUTDOOR]: "Outdoor",
  [BRAND_CODE.INTERIORS]: "Interiors",
  [BRAND_CODE.CONTEMPORARY]: "Contemporary"
};

export const getBrandContext = brandContextMap[getBrand()];

export const getHostName = (brand: keyof typeof SUBDOMAIN) => {
  const [subDomain, ...rest] = window.location.hostname.split(".");
  const parsedHost =
    subDomain in brandMap ? rest.join(".") : window.location.hostname;

  return `${window?.location?.protocol}//${SUBDOMAIN[brand]}.${parsedHost}${
    window?.location?.port && `:${window.location.port}`
  }`;
};

const atgValidSiteMap = {
  [DOMAIN_RH_CORE]: BRAND_CODE.CORE,
  [SUBDOMAIN.MO]: BRAND_CODE.MODERN,
  [SUBDOMAIN.BC]: BRAND_CODE.BABYCHILD,
  [SUBDOMAIN.TN]: BRAND_CODE.TEEN
};

export const shopByRoomDomains = {
  RH: "fld-shop-rooms",
  BH: "fld-explorebeachhouse",
  SH: "fld-exploreskihouse",
  OD: "fld-exploreoutdoor",
  CN: "fld-explorecontemporary",
  BC: "bc-fld-shop-rooms",
  TN: "bc-fld-shop-rooms",
  MO: "fld-shop-rooms",
  IN: "fld-shop-rooms",
  RHUK: "fld-shop-rooms"
};

/**
 * # use Brand for ATG
 * Site id must be one of RH, BC, TN, MO.
 *
 * Furthermore CN OD BH SH are not atg sites.
 * Using one of these non-sites in an atg call will do undesirable things
 *
 * If the brand is CD this should return RH so ATG will be happy and keep on trucking.
 *
 * Falls back to BRAND_CODE.CORE
 * @deprecated - please use useSite hook
 */
export function useBrandATG(hostname: string = window.location.hostname) {
  const [subDomain] = hostname.split(".");
  return atgValidSiteMap[subDomain] || BRAND_CODE.CORE;
}

export function getBrandATG(hostname: string = window.location.hostname) {
  const [subDomain] = hostname.split(".");
  return atgValidSiteMap[subDomain] || BRAND_CODE.CORE;
}

//-----TODO: remove reliance on this context map after BCC updates links to point towards proper site subdomains-----

export default getBrand;
