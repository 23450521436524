import React, { FC } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";
import memoize from "utils/memoize";
import { usePageContent } from "customProviders/LocationProvider";
import { Opens_in_a } from "resources/footer-links.json";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 75
    }
  })
);

export const ExternalLinkArrowIcon: FC<SvgIconProps> = props => {
  const { pageContent } = usePageContent();
  const classes = useStyles();

  const ICON_TEXT = pageContent.footerLinks?.Opens_in_a ?? Opens_in_a;

  return (
    <SvgIcon
      viewBox="0 0 10 10"
      {...props}
      className={classNames(classes.root, props.className)}
    >
      {/* <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <title>{ICON_TEXT}</title>
      <path
        d="M7.79862 8.20447C7.68413 8.20447 7.59145 8.11179 7.59145 7.99729V2.40892H2.00308C1.88858 2.40892 1.7959 2.31624 1.7959 2.20175C1.7959 2.08725 1.88858 1.99457 2.00308 1.99457H7.79862C7.91312 1.99457 8.0058 2.08725 8.0058 2.20175V8.00275C8.0058 8.11724 7.91312 8.20992 7.79862 8.20992V8.20447Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="0.25"
      />
      <path
        d="M2.00249 8.20453C1.94797 8.20453 1.8989 8.18272 1.85528 8.14456C1.7735 8.06278 1.7735 7.93738 1.85528 7.8556L7.65083 2.06005C7.73261 1.97827 7.85801 1.97827 7.93979 2.06005C8.02157 2.14184 8.02157 2.26723 7.93979 2.34901L2.14424 8.14456C2.10608 8.18272 2.05156 8.20453 1.99703 8.20453H2.00249Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="0.25"
      />
      {/* </svg> */}
    </SvgIcon>
  );
};

export default memoize(ExternalLinkArrowIcon);
