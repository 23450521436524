import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

export const PlayIcon: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 24,
    width: "42px",
    height: "58px",
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 42 58" {...props} style={style}>
      <title>Triangle</title>
      <desc>Play Icon</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.7"
      >
        <polygon
          id="Triangle"
          fill="#FFFFFF"
          transform="translate(21.000000, 29.000000) rotate(-270.000000) translate(-21.000000, -29.000000) "
          points="21 8 50 50 -8 50"
        ></polygon>
      </g>
    </SvgIcon>
  );
};

export default PlayIcon;
