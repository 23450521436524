import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

interface GridColumnIconProps extends SvgIconProps {
  column: number;
}

export const GridColumnIcon: FC<GridColumnIconProps> = props => {
  const style = {
    backgroundColor: "transparent",
    width: "14px",
    height: "14px",
    ...props.style
  };
  const env = useEnv();
  const isTailwindIconEnabled = yn(env.FEATURE_TAILWIND_ICON);
  return isTailwindIconEnabled ? (
    // Tailwind Icon
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
      style={style}
    >
      {props.column === 1 && (
        <rect x="0" width="14" height="14" fill="currentColor" />
      )}
      {props.column === 2 && (
        <>
          <rect width="6" height="6" fill="currentColor" />
          <rect y="8" width="6" height="6" fill="currentColor" />
          <rect x="8" width="6" height="6" fill="currentColor" />
          <rect x="8" y="8" width="6" height="6" fill="currentColor" />
        </>
      )}
      {props.column === 3 && (
        <>
          <rect width="3.33232" height="3.33232" fill="currentColor" />
          <rect
            y="5.33398"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            y="10.668"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            x="5.33203"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            x="5.33203"
            y="5.33398"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            x="5.33203"
            y="10.668"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            x="10.668"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            x="10.668"
            y="5.33398"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
          <rect
            x="10.668"
            y="10.668"
            width="3.33232"
            height="3.33232"
            fill="currentColor"
          />
        </>
      )}
    </svg>
  ) : (
    // MUI Icon
    <SvgIcon viewBox="0 0 14 14" {...props} style={style}>
      {props.column === 1 && (
        <rect x="0" width="14" height="14" fill="currentColor" />
      )}
      {props.column === 2 && (
        <>
          <rect x="0" width="6" height="6" fill="currentColor" />
          <rect x="8" width="6" height="6" fill="currentColor" />
          <rect x="0" y="8" width="6" height="6" fill="currentColor" />
          <rect x="8" y="8" width="6" height="6" fill="currentColor" />
        </>
      )}
      {props.column === 3 && (
        <>
          <rect x="0" width="3.33" height="3.33" fill="currentColor" />
          <rect x="5.33" width="3.33" height="3.33" fill="currentColor" />
          <rect x="10.66" width="3.33" height="3.33" fill="currentColor" />
          <rect x="0" y="5.33" width="3.33" height="3.33" fill="currentColor" />
          <rect
            x="5.33"
            y="5.33"
            width="3.33"
            height="3.33"
            fill="currentColor"
          />
          <rect
            x="10.66"
            y="5.33"
            width="3.33"
            height="3.33"
            fill="currentColor"
          />
          <rect
            x="0"
            y="10.66"
            width="3.33"
            height="3.33"
            fill="currentColor"
          />
          <rect
            x="5.33"
            y="10.66"
            width="3.33"
            height="3.33"
            fill="currentColor"
          />
          <rect
            x="10.66"
            y="10.66"
            width="3.33"
            height="3.33"
            fill="currentColor"
          />
        </>
      )}
    </SvgIcon>
  );
};

export default GridColumnIcon;
