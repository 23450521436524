import React, { FC } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { SvgIcon, Theme } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";
import { BREAKPOINT_MD, BREAKPOINT_SM } from "utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "20px",
      height: "20px !important",
      [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
        height: "22.8px !important",
        width: "22.8px"
      }
    }
  })
);

export const CarouselArrowIcon: FC<SvgIconProps> = ({
  stroke,
  style = {},
  fill = "white",
  ...props
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      {...props}
      className={classNames(classes?.root, props?.className)}
      style={{ fill: "none", ...style }}
    >
      <path
        d="M5 10.1015H15.834H5ZM15.834 10.1015L10.417 15.5185L15.834 10.1015ZM15.834 10.1015L10.417 4.68555L15.834 10.1015Z"
        fill="#101926"
      />
      <path
        d="M5 10.1015H15.834M15.834 10.1015L10.417 15.5185M15.834 10.1015L10.417 4.68555"
        stroke="#101926"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default CarouselArrowIcon;
