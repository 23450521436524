import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";

export type RHRTeenIconProps = SvgIconProps & {
  strokeColor?: string;
  fillColor?: string;
};

export const RHRTeenIcon: FC<RHRTeenIconProps> = ({
  strokeColor,
  fillColor,
  ...props
}) => {
  return (
    <>
      <SvgIcon
        viewBox="0 0 70 50"
        {...props}
        className={classNames("text-black", props.className)}
      >
        <path
          d="M10.1129 20.7764L10.1129 1.50312L20.8403 1.50312C27.8368 1.50313 32.3529 4.81029 32.3529 11.2579C32.2973 17.761 27.7812 20.7764 20.8403 20.7764L10.1129 20.7764ZM59.8732 0.95425L59.8732 20.7764L38.5434 20.7764L38.5434 0.954248L36.9732 0.954248L36.9732 39.6814C35.5628 39.605 33.7563 38.5211 30.4839 31.6428L29.4348 29.4959C27.1767 24.7575 24.481 22.2354 20.6805 21.3531L20.8473 21.3531C28.9971 21.3531 33.9022 17.8166 33.9022 11.2579C33.9022 4.69913 28.9484 0.954248 20.7917 0.954247L8.54963 0.954246L8.54963 40.2928L10.1198 40.2928L10.1198 21.3878L17.4845 21.3878C22.5564 21.3878 25.0368 24.153 27.4547 29.1138L28.4482 31.2051C32.1861 38.8894 35.1459 40.2164 36.9801 40.2928C37.0427 40.2928 37.0982 40.2928 37.1538 40.2928L38.5503 40.2928L38.5503 21.3878L59.8802 21.3878L59.8802 40.2928L61.4504 40.2928L61.4504 0.95425L59.8802 0.95425L59.8732 0.95425Z"
          fill={fillColor}
        />
        <path
          d="M21.1808 43.1693L19.3952 43.1693L19.3952 42.8288L23.7098 42.8288L23.7098 43.1693L21.9242 43.1693L21.9242 48.8039L21.1739 48.8039L21.1739 43.1693L21.1808 43.1693Z"
          fill={fillColor}
        />
        <path
          d="M28.4969 42.8288L32.3321 42.8288L32.3321 43.1693L29.2264 43.1693L29.2264 45.5107L31.9777 45.5107L31.9777 45.8511L29.2264 45.8511L29.2264 48.4635L32.3876 48.4635L32.3876 48.8039L28.5038 48.8039L28.5038 42.8288L28.4969 42.8288Z"
          fill={fillColor}
        />
        <path
          d="M37.3484 42.8288L41.1836 42.8288L41.1836 43.1693L38.0779 43.1693L38.0779 45.5107L40.8292 45.5107L40.8292 45.8511L38.0779 45.8511L38.0779 48.4635L41.2392 48.4635L41.2392 48.8039L37.3553 48.8039L37.3553 42.8288L37.3484 42.8288Z"
          fill={fillColor}
        />
        <path
          d="M46.2555 42.8288L46.9294 42.8288L50.0212 47.5186L50.0212 42.8288L50.6048 42.8288L50.6048 48.8039L50.0351 48.8039L46.853 43.9613L46.853 48.8039L46.2555 48.8039L46.2555 42.8288Z"
          fill={fillColor}
        />
      </SvgIcon>
    </>
  );
};

export default RHRTeenIcon;
