import useRHBrand from "@RHCommerceDev/hooks/useRHBrand";
import {
  BC_PRIMARY_COLOR,
  FONT_ACUMIN_PRO,
  FONT_BARON_SANS,
  FONT_BARON_SANS_EXTRA_LIGHT,
  FONT_BARON_SANS_LIGHT,
  FONT_BARON_SANS_ROMAN,
  FONT_BARON_SANS_THIN,
  FONT_BARON_SANS_ULTRA_THIN,
  FONT_BOLD,
  FONT_CASLON,
  FONT_CASLON_SUPERFINE,
  FONT_LIGHT,
  FONT_MEDIUM,
  FONT_REGULAR,
  FONT_RHC_ROMAN,
  FONT_THIN,
  FONT_BARON_SANS_BASICS,
  FONT_BARON_SANS_LIGHT_BASICS,
  MO_PRIMARY_COLOR,
  MO_SECONDARY_COLOR,
  CATALOG_SALE_COLOR,
  RH_DARK
} from "@RHCommerceDev/utils/constants";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";

const env = useEnv();

const rhBaseH = {
  fontFamily: FONT_CASLON,
  fontWeight: FONT_THIN,
  color: "#000000"
};

export const commonTypography = (theme: Theme) => ({
  rhSaleBaseH1: {
    fontFamily: FONT_CASLON_SUPERFINE,
    fontWeight: FONT_THIN,
    color: "#FFFFFF",
    letterSpacing: "-0.5px",
    lineHeight: theme.typography.pxToRem(61),
    fontSize: theme.typography.pxToRem(52),
    [theme.breakpoints.down("xl")]: {
      lineHeight: theme.typography.pxToRem(38),
      fontSize: theme.typography.pxToRem(40)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(24)
    }
  },
  reimagineH1Baron: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(32),
    fontStyle: "normal",
    fontWeight: 75,
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: theme.typography.pxToRem(-0.2)
  },

  reimagineH3Baron: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(20),
    fontStyle: "normal",
    fontWeight: 75,
    lineHeight: theme.typography.pxToRem(23),
    letterSpacing: theme.typography.pxToRem(-0.4)
  },
  reimagineH4Baron: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_REGULAR,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17)
  },
  rhBaseH6: {
    ...rhBaseH,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(15)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  rhBaseH7: {
    fontSize: `${theme.typography.pxToRem(12)} !important`,
    fontFamily: `${FONT_BARON_SANS} !important`,
    fontWeight: `${FONT_REGULAR} !important`,
    color: `${theme.palette.common.black} !important`
  },
  rhBase0: {
    fontFamily: FONT_BARON_SANS_THIN,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "14px",
    letterSpacing: "0.015em"
  },
  rhBaseBodyRoman: {
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  reimagineBody1: {
    fontFamily: FONT_BARON_SANS_THIN,
    fontSize: theme.typography.pxToRem(13),
    fontStyle: "normal",
    fontWeight: FONT_THIN,
    lineHeight: theme.typography.pxToRem(19.5),
    letterSpacing: theme.typography.pxToRem(0.2)
  },
  reimagineBody2: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: theme.typography.pxToRem(13),
    fontStyle: "normal",
    fontWeight: FONT_LIGHT,
    lineHeight: theme.typography.pxToRem(19.5),
    letterSpacing: theme.typography.pxToRem(0.195)
  },
  rhBaseBody3: {
    fontFamily: `${FONT_BARON_SANS_THIN} !important`,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.black,
    fontWeight: FONT_THIN,
    lineHeight: "13px",
    letterSpacing: "0.015em"
  },
  rhBaseBody4: {
    fontFamily: FONT_BARON_SANS_EXTRA_LIGHT,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: "0.015em",
    color: "#000000"
  },
  rhBaseBody5: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: FONT_LIGHT,
    lineHeight: "15.6px",
    height: "16px",
    fontStyle: "normal",
    letterSpacing: "0.52px"
  },
  reimagineCaptionCaps: {
    fontFamily: FONT_BARON_SANS_BASICS,
    fontSize: theme.typography.pxToRem(11),
    fontStyle: "normal",
    fontWeight: FONT_LIGHT,
    lineHeight: theme.typography.pxToRem(13.2),
    letterSpacing: theme.typography.pxToRem(0.44),
    textTransform: "uppercase" as const
  },
  rhBaseCaption1: {
    fontFamily: FONT_BARON_SANS_THIN,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.black,
    fontWeight: FONT_THIN,
    lineHeight: "20px",
    letterSpacing: "0.015em",
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(11)
    }
  },
  rhBaseCaption11: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.white,
    fontWeight: FONT_THIN,
    lineHeight: "10px",
    letterSpacing: "0.04em",
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(11)
    }
  },
  rhBaseCaption2: {
    fontFamily: FONT_BARON_SANS_THIN,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.black,
    fontWeight: FONT_THIN,
    lineHeight: "20px",
    letterSpacing: "0.015em"
  },
  rhSaleCaption: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: "0.05em",
    color: "#FFFFFF"
  },
  rhTextLink: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_REGULAR,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    letterSpacing: "0.03em"
  },
  rhBaseLink1: {
    fontWeight: FONT_REGULAR,
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    letterSpacing: "0.04em",
    color: "#000000",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
    paddingBottom: "3px",
    "text-decoration-thickness": "0.5px"
  },
  rhBaseLink2: {
    fontWeight: FONT_REGULAR,
    textDecoration: "underline",
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    letterSpacing: "0.04em",
    color: "#000000"
  },
  rhBaseLink3: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_REGULAR,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: "#000000",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    textDecoration: "underline"
  },
  rhBaseLink4: {
    fontFamily: FONT_RHC_ROMAN,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: "0.05em"
  },
  rhBaseH1: {
    fontFamily: FONT_BARON_SANS_ULTRA_THIN,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(32),
    color: "#000000",
    letterSpacing: "-0.02em"
  },
  rhSuperScript: {
    fontSize: theme.typography.pxToRem(13.5),
    fontFamily: FONT_BARON_SANS_LIGHT_BASICS,
    color: "rgba(0,0,0,0.6)"
  },
  rhBaseH2: {
    fontFamily: FONT_BARON_SANS_ULTRA_THIN,
    fontSize: `${theme.typography.pxToRem(27)} !important`,
    lineHeight: theme.typography.pxToRem(31),
    color: "#000000",
    letterSpacing: "-0.02em"
  },
  rhBaseH3: {
    fontFamily: FONT_BARON_SANS_ULTRA_THIN,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    color: "#000000",
    letterSpacing: "-0.02em"
  },
  rhBaseH4: {
    fontFamily: FONT_BARON_SANS_ULTRA_THIN,
    fontWeight: FONT_LIGHT,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(17)
  },
  rhBaseH5: {
    fontWeight: FONT_REGULAR
  },
  rhBaseBody1: {
    fontFamily: FONT_BARON_SANS_THIN,
    fontWeight: FONT_LIGHT,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    color: "#404040",
    letterSpacing: "0.015em",
    textTransform: "initial" as const
  },
  rhEditButton: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13.2),
    color: "#000",
    letterSpacing: "0.44px",
    textTransform: "initial" as const
  },
  rhBaseBody2: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontWeight: FONT_THIN,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.common.black,
    letterSpacing: "0.015em",
    textTransform: "initial" as const
  },
  rhRHSans: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: "11px",
    color: theme.palette.common.black,
    textTransform: "uppercase"
  },
  rhRHSans1: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13.2),
    letterSpacing: "0.44px"
  },
  rhRHSans2: {
    fontWeight: FONT_MEDIUM,
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(11)
  },
  rhRHSans3: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: FONT_LIGHT,
    fontStyle: "normal",
    color: `${RH_DARK} !important`,
    lineHeight: theme.typography.pxToRem(12.1),
    letterSpacing: "0.44px"
  },
  rhBaseCaption: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    color: "#000000",
    letterSpacing: "0.04em"
  },
  rhBaseCaption10: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontWeight: FONT_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    color: "#898886",
    letterSpacing: "0.04em"
  },
  mediumFontWeight: {
    fontWeight: FONT_MEDIUM
  },
  boldFontWeight: {
    fontWeight: FONT_BOLD
  },
  textAlignRight: {
    textAlign: "right"
  },
  textAlignCenter: {
    textAlign: "center"
  },
  fontRHSans: {
    fontFamily: FONT_BARON_SANS
  },
  tableText: {
    fontSize: theme.typography.pxToRem(12),
    wordBreak: "break-word"
  },
  tableHeader: {
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: "nowrap",
    wordBreak: "keep-all"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  grayText: {
    color: "#666666"
  },
  lightGrayText: {
    color: "#808080"
  },
  rhDark: {
    color: "#898886"
  },
  bodyText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12)
  },
  warningText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12)
  },
  blackText: {
    color: theme.palette.common.black
  },
  errorText: {
    color: "#CA6667"
  },
  whiteText: {
    color: "#FFFFFF"
  },
  modalTitles: {
    fontFamily: FONT_BARON_SANS,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.1),
    paddingRight: theme.spacing(0.1)
  },
  modalContentHeaders: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(24),
    textAlign: "center",
    letterSpacing: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: theme.spacing(1.8),
    marginLeft: 0,
    textTransform: "uppercase",
    paddingTop: theme.spacing(1)
  },
  modalContentSubHeader: {
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(10),
    letterSpacing: theme.typography.pxToRem(1),
    fontSize: theme.typography.pxToRem(11),
    fontWeight: "normal",
    textTransform: "uppercase",
    textAlign: "center"
  },
  modalContentPPSubHeader: {
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(10),
    letterSpacing: theme.typography.pxToRem(1),
    fontSize: theme.typography.pxToRem(11),
    fontWeight: FONT_BOLD,
    textTransform: "uppercase",
    textAlign: "center"
  },
  strongTextToPersonalizationCollapse: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    color: theme.palette.common.black,
    fontWeight: FONT_BOLD,
    textDecoration: "none"
  },
  modalContentContainer: {
    marginTop: 0,
    marginRight: theme.typography.pxToRem(10),
    marginBottom: theme.typography.pxToRem(9),
    marginLeft: 0,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(12),
    color: "#3c3938"
  },
  modalContentContainerLink: {
    textDecoration: "underline"
  },
  modalTableCell: {
    color: "#3c3938",
    fontSize: theme.typography.pxToRem(11),
    padding: theme.typography.pxToRem(8)
  },
  canadianModalTableCell: {
    color: "#3c3938",
    fontSize: theme.typography.pxToRem(11),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8)
  },
  primaryText: {
    color: theme.palette.common.black
  },
  secondaryText: {
    color: "#999"
  },
  cartLinkText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    color: "#999",
    textDecoration: "none"
  },
  uppercaseText: {
    textTransform: "uppercase"
  },
  underlineText: {
    textDecoration: "underline"
  },
  textLeft: {
    textAlign: "left"
  },
  textCenter: {
    textAlign: "center"
  },
  textLinkTwo: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: "#666666",
    letterSpacing: 0.2,
    cursor: "pointer"
  },
  textLinkThree: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.common.black,
    letterSpacing: 0.5,
    cursor: "pointer"
  },
  productCartTitle: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    color: theme.palette.common.black,
    letterSpacing: 0.2,
    textTransform: "uppercase"
  },
  dialogHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    color: "#666"
  },
  dialogSubheader: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    fontWeight: "normal",
    color: theme.palette.common.black
  },
  dialogSubheaderChosen: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "normal",
    color: theme.palette.common.black
  },
  checkoutSectionHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    color: "#333",
    letterSpacing: ".05em",
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(3, 0),
      paddingBottom: theme.spacing(2, 0),
      width: "100%",
      fontSize: theme.typography.pxToRem(24),
      textAlign: "center"
    }
  },
  tradeSalesImageTitle: {
    fontSize: theme.typography.pxToRem(36),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_THIN,
    color: "#3C3C3C",
    textTransform: "uppercase"
  },
  registryHeroTitle: {
    fontSize: theme.typography.pxToRem(56),
    lineHeight: theme.typography.pxToRem(56),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_THIN,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    letterSpacing: theme.typography.pxToRem(-4),
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(38),
      lineHeight: theme.typography.pxToRem(38)
    }
  },
  registryHeroTitleComplement: {
    fontStyle: "italic"
  },
  registryHeroSubtitle: {
    fontStyle: "italic",
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(40),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_THIN,
    color: theme.palette.common.white,
    letterSpacing: theme.typography.pxToRem(-2),
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(30)
    }
  },
  registryHeroDescriptionSecondary: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_BOLD,
    textTransform: "uppercase",
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16)
    }
  },
  registryHeroDescription: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_BOLD,
    textTransform: "uppercase",
    color: theme.palette.common.white,
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16)
    }
  },
  registrySubheader: {
    fontFamily: FONT_BARON_SANS,
    paddingBottom: theme.spacing(3, 0),
    color: theme.palette.common.black,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(14)
  },
  registrySectionHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(18),
    padding: theme.spacing(3, 0),
    textTransform: "uppercase",
    color: "#333",
    letterSpacing: ".05em"
  },
  checkoutBiggerSectionHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    textTransform: "uppercase",
    color: "#333",
    letterSpacing: ".05em"
  },
  checkoutUpdateBiggerSectionHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(18),
    textTransform: "uppercase",
    color: "#333",
    letterSpacing: ".05em"
  },
  cartHeaderTitle: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(11),
    textTransform: "uppercase",
    color: "#999"
  },
  cartHeaderSeparator: {
    borderBottom: "1px solid black"
  },
  quantityAction: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase"
  },
  removeLineItemIcon: {
    fontSize: 9,
    cursor: "pointer"
  },
  lineItemAction: {
    fontSize: theme.typography.pxToRem(10),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    cursor: "pointer"
  },
  lineItemText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: theme.palette.common.black
  },
  buttonFontSize: {
    fontSize: theme.typography.pxToRem(12)
  },
  pricingTotal: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: "#000",
    fontWeight: FONT_BOLD
  },
  strongText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: theme.palette.common.black,
    fontWeight: FONT_BOLD
  },
  regularText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    color: "#333"
  },
  lineItemDisplayName: {
    color: "#666666",
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: FONT_CASLON
  },
  lineItemKey: {
    color: "#999999",
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(13),
    fontFamily: FONT_BARON_SANS
  },
  skuList: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_REGULAR,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: 0.2
  },
  skuListSpo: {
    fontFamily: FONT_BARON_SANS_LIGHT,
    fontWeight: FONT_LIGHT,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: 0.44
  },
  skuListColumn1: {
    textTransform: "capitalize",
    color: "#666666"
  },
  skuListColumn2: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.black,
    overflow: "hidden"
  },
  skuListRegular: {
    textTransform: "initial"
  },
  skuListTotalPrice: {
    textTransform: "initial",
    fontWeight: FONT_MEDIUM,
    color: theme.palette.common.black
  },
  skuListMemberPrice: {
    textTransform: "initial",
    fontWeight: FONT_MEDIUM
  },
  skuListMemberPrice2: {
    textTransform: "initial",
    fontWeight: FONT_MEDIUM,
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  skuListSale: {
    color: " #CA2022",
    textTransform: "initial",
    fontWeight: FONT_REGULAR
  },
  skuListSale2: {
    color: " #CA2022",
    textTransform: "initial",
    fontWeight: FONT_THIN,
    fontFamily: FONT_BARON_SANS_THIN
  },
  priceRangeSmall: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(15),
    letterSpacing: 0.16
  },
  priceRange: {
    color: theme.palette.common.black,
    fontWeight: FONT_THIN
  },
  priceRangeLabelAndMember: {
    color: theme.palette.common.black,
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  addOnPriceRangeLabelAndMember: {
    color: theme.palette.common.black,
    fontFamily: `${FONT_BARON_SANS_THIN} !important`
  },
  priceRangeSaleLabelAndMember: {
    color: "#CA2022 !important",
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  newPriceRangeSaleLabelAndMember: {
    color: "#CA2022 !important",
    fontFamily: `RHSans !important`
  },
  saleFontWeight: {
    fontWeight: "100 !important"
  },
  swatchSaleLabel: {
    color: CATALOG_SALE_COLOR,
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "400",
    lineHeight: "12px",
    fontFamily: "RHSans-Roman, Helvetica, Arial"
  },
  priceRangeStrikethroughRegular: {
    color: "#898886",
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  priceRangeStrikethroughMember: {
    color: "#898886",
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  priceRangeRegular: {
    color: "#000000",
    fontFamily: `${FONT_BARON_SANS_THIN} !important`
  },
  priceRangeRegular2: {
    color: "#000000",
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  priceRangeSaleRegular: {
    color: "#CA2022 !important",
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  priceRangeSale: {
    color: "#CA2022 !important",
    fontFamily: FONT_BARON_SANS_LIGHT
  },
  priceRangeSale2: {
    color: "#CA2022 !important",
    fontFamily: `${FONT_BARON_SANS_THIN} !important`
  },
  priceRangeMemberSale: {
    color: "#CA2022 !important",
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  priceViewSaleLinkBtn: {
    "&:focus, &:focus-visible, &:focus-within": {
      outline: "1px solid #000000"
    }
  },
  priceViewSaleLink: {
    color: "#CA2022 !important",
    paddingTop: "16px",
    textDecoration: "underline"
  },
  priceViewSale: {
    color: "#CA2022 !important",
    paddingTop: "16px"
  },
  priceRangeMember: {
    color: theme.palette.common.black,
    fontFamily: FONT_BARON_SANS_LIGHT
  },
  priceRangeMember2: {
    color: theme.palette.common.black,
    fontFamily: `${FONT_BARON_SANS_ROMAN} !important`
  },
  cardPriceRangeBold: {
    color: theme.brandColors?.priceRange,
    fontWeight: FONT_MEDIUM
  },
  memberPriceWhite: {
    color: theme.brandColors?.lightMemberPrice
  },
  collectionPriceRange: {
    // consider removing once RH Modern has its own styles
    color: theme.brandColors?.priceRange,
    fontWeight: FONT_THIN,
    fontSize: 12
  },
  collectionAccordion: {
    // consider removing once RH Modern has its own styles
    color: theme.brandColors?.priceRange,
    fontWeight: FONT_REGULAR,
    fontSize: 12
  },
  collectionGalleryHeader: {
    fontSize: theme.typography.pxToRem(40),
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(30)
    }
  },
  collectionGallerySubHeader: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: "-4px",
    display: "block",
    textTransform: "uppercase",
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(18)
    }
  },
  priceRangeBold: {
    fontWeight: "initial"
  },
  priceRangeBold2: {
    fontWeight: FONT_BOLD
  },
  priceRangeCaption: {
    lineHeight: theme.typography.pxToRem(14),
    fontSize: theme.typography.pxToRem(12)
  },
  priceRangeCaptionSmall: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(13),
    letterSpacing: 0.18
  },
  membershipDialogHeader: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_CASLON,
    textTransform: "uppercase",
    color: theme.palette.common.black
  },
  membershipSavingsPrice: {
    color: "#c66"
  },
  saleTitle: {
    fontFamily: FONT_BARON_SANS_LIGHT
  },
  saleSubTitle: {
    fontFamily: FONT_BARON_SANS_THIN
  },
  deleteAddressButton: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  deleteSuccessText: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    paddingLeft: "4rem",
    paddingRight: "4rem"
  },
  deleteAddressLink: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#000",
    height: "4rem"
  },
  membershipDialogTextSpacing: {
    marginTop: theme.spacing(2)
  },
  membershipDialogTypeRegular: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: FONT_BARON_SANS,
    color: theme.palette.common.black
  },
  menuCatalogItem: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_THIN,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(40),
    color: theme.palette.common.black,
    letterSpacing: 1.5,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(50),
      letterSpacing: 1.95
    }
  },
  searchInput: {
    "& input": {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  searchAutoSuggestResult: {
    fontWeight: FONT_THIN,
    textTransform: "uppercase",
    color: "#666666",
    "& > strong": {
      opacity: 1,
      color: theme.palette.common.black,
      fontWeight: FONT_MEDIUM
    },
    "&:hover": {
      color: theme.palette.common.black,
      opacity: 1
    }
  },
  autoSearchSectionTitle: {
    marginTop: 20,

    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      marginBottom: 8,
      marginTop: 28
    },
    "&.product-title": {
      marginTop: 24,
      [theme.breakpoints.up("md")]: {
        marginTop: 36
      }
    }
  },

  rhrSearchTitle: {
    marginBottom: 16,
    marginTop: 32
  },
  rhrSearchAutoSuggestResult: {
    display: "inline-block",
    lineHeight: 1.5,
    textTransform: "uppercase",
    borderBottom: "1px solid transparent",
    [theme.breakpoints.up("md")]: {
      marginBottom: 5
    },
    "& > strong": {
      color: theme.palette.common.black
    },
    "&:hover": {
      borderBottomColor: theme.palette.common.black
    }
  },
  textLink: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color: "#666666"
  },
  desktopCatalogLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    textDecoration: "none",
    letterSpacing: 1.5,
    color: "inherit",
    cursor: "pointer",
    fontWeight: 400,
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  },
  header: {
    color: "white",
    textTransform: "none",
    lineHeight: 1.15
  },
  headerTwo: {
    fontSize: theme.typography.pxToRem(36)
  },
  headerOne: {
    fontSize: theme.typography.pxToRem(48)
  },
  desktopNavLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 0.5,
    textTransform: "none",
    cursor: "pointer",
    color: theme.palette.common.black,
    transition: "color 200ms",
    "&.terminal:hover": {
      color: "#333333",
      backgroundColor: "transparent"
    }
  },
  desktopNavLinkHighlight: {
    fontWeight: FONT_MEDIUM,
    "&:hover": {
      color: theme.palette.common.black
    }
  },
  desktopNavLinkShop: {
    backgroundColor: "#fafafa",
    marginBottom: 2,
    marginTop: 2,
    paddingTop: 12,
    paddingBottom: 12
  },
  pointerHover: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  breadcrumbLink: {
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 600,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  },
  breadcrumbLinkActive: {
    color: theme.palette.common.black,
    pointerEvents: "none",
    cursor: "default",
    textDecoration: "none"
  },
  breadcrumbLinkInactive: {
    color: "#999"
  },
  breadcrumbLinkDisabled: {
    color: "#ccc"
  },
  checkoutLeftSidebarTitle: {
    fontFamily: FONT_CASLON,
    textTransform: "uppercase",
    color: "#333",
    fontSize: theme.typography.pxToRem(14)
  },
  checkoutAddressTitles: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "normal",
    color: theme.palette.common.black
  },
  checkoutGiftCardBalance: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: FONT_BOLD
  },
  orderPlacementRegularText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "normal",
    color: theme.palette.common.black
  },
  creditCardCVVDialogBox: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(12),
    color: "#333333",
    textTransform: "uppercase"
  },
  italicText: {
    fontStyle: "italic"
  },
  myAccountMainHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    color: "#666"
  },
  myAccountInnerHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.black
  },
  myAccountLink: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    color: "#777",
    textTransform: "uppercase",
    textDecoration: "none"
  },
  personalizedLineItem: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.black,
    textTransform: "uppercase",
    textAlign: "end",
    textDecoration: "none"
  },
  lightCatalogNavigation: {
    color: "white"
  },
  navLink: {
    color: "white",
    textDecoration: "none",
    display: "inline-block",
    fontWeight: FONT_BOLD
  },
  registryNav: {
    textDecoration: "none",
    display: "inline-block",
    "&:hover": {
      color: theme.palette.common.black
    },
    "&.active": {
      color: theme.palette.common.black,
      fontWeight: FONT_BOLD
    }
  },
  defaultCatalogNavigation: {
    color: theme.palette.common.black
  },
  fullBorder: {
    border: "solid 1px black",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(1)
  },
  rhLinkWithoutLineBreak: {
    display: "inline"
  },
  rhLinkNoHover: {
    textDecoration: "underline"
  },
  registryTitles: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(14)
  },
  addressValidationTitles: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold"
  },
  addressValidationSubtitles: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold"
  },
  capitalizeText: {
    textTransform: "capitalize"
  },
  customerServiceMainHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    color: "#666"
  },
  customerServiceInnerHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    color: "#000"
  },
  customerServiceLink: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    color: "#777",
    textTransform: "uppercase",
    textDecoration: "none"
  },
  checkoutUpdatePaymentHeader: {
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: "#000",
    fontWeight: FONT_BOLD,
    fontSize: theme.typography.pxToRem(14)
  },
  checkoutUpdateSessionHeader: {
    fontFamily: FONT_CASLON,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.black
  },
  checkoutUpdateLabel: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: FONT_BOLD,
    color: theme.palette.common.black
  },
  boldTradeTitles: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: FONT_BOLD,
    color: theme.palette.common.black
  },
  paymentTypography: {
    /* Core / H5 */

    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    /* identical to box height, or 150% */

    letterSpacing: "1px",
    textTransform: "uppercase",

    color: "#000000"
  },
  paymentDetailTypograpgy: {
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    /* or 138% */

    letterSpacing: "0.2px",
    color: "#666666"
  },
  paymentAction: {
    fontSize: "12px",
    lineHeight: "16px",
    /* identical to box height, or 133% */

    letterSpacing: "0.2px",
    textDecorationLine: "underline",
    whiteSpace: "nowrap",
    color: "#666666",
    paddingTop: "3rem",
    paddingRight: "3rem"
  },
  billingSummaryHeading: {
    marginBottom: "1.5rem",
    fontWeight: 400,
    fontSize: "25px",
    color: "#000000",
    fontFamily: "Caslon RH",
    fontStyle: "normal",
    lineHeight: "23px"
  },
  billingSummaryCellFont: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "25px",
    letterSpacing: "0.2px",
    fontStyle: "normal",
    fontFamily: "Baron Sans"
  },
  billingSummaryTableHeading: {
    fontFamily: "Baron Sans",
    fontWeight: 650,
    fontSize: "12px",
    lineHeight: "25px",
    letterSpacing: "0.5px",
    color: "#000000"
  },
  billingSummaryInvoicePadding: {
    paddingLeft: "5rem"
  },
  billingSummaryTableHeader: {
    borderBottom: "unset !important",
    padding: "unset"
  },
  billingSummaryIcon: {
    marginLeft: "1rem",
    width: "31px",
    height: "23px"
  },
  imageNotFoundHeaderText: {
    fontFamily: "RHSans-Light, Helvetica, Arial",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "30px",
    lineHeight: "90%",
    textAlign: "center",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#898886",
    [theme.breakpoints.down("xl")]: {
      fontSize: "25px"
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "10px",
      lineHeight: "100%"
    }
  },
  imageNotFoundChatLink: {
    textDecoration: "underline",
    textUnderlineOffset: "3px",
    paddingBottom: "3px",
    color: "#666"
  }
});

const typographyStyles = (theme: Theme) => ({
  ...commonTypography(theme)
});

const bcTypographyStyles = (theme: Theme) => ({
  ...commonTypography(theme),
  bodyText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12)
  },
  desktopCatalogLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 1.5,
    fontFamily: FONT_ACUMIN_PRO,
    cursor: "pointer",
    color: BC_PRIMARY_COLOR,
    fontWeight: 400,
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  membershipDialogHeader: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_BOLD,
    textTransform: "uppercase",
    color: BC_PRIMARY_COLOR
  },
  membershipDialogTypeRegular: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: FONT_ACUMIN_PRO,
    color: BC_PRIMARY_COLOR
  },
  strongText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_CASLON,
    textTransform: "uppercase",
    color: BC_PRIMARY_COLOR,
    fontWeight: FONT_BOLD
  },
  regularText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_ACUMIN_PRO,
    color: BC_PRIMARY_COLOR
  },
  checkoutAddressTitles: {
    fontFamily: FONT_ACUMIN_PRO,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: "normal",
    color: BC_PRIMARY_COLOR
  },
  lightCatalogNavigation: {
    color: "white"
  },
  defaultCatalogNavigation: {
    color: BC_PRIMARY_COLOR
  },
  registryHeroTitle: {
    fontSize: theme.typography.pxToRem(192),
    lineHeight: theme.typography.pxToRem(144),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_THIN,
    color: theme.palette.common.white,
    letterSpacing: theme.typography.pxToRem(-4),
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(164),
      lineHeight: theme.typography.pxToRem(116)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(136),
      lineHeight: theme.typography.pxToRem(124)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(96),
      lineHeight: theme.typography.pxToRem(68)
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: theme.typography.pxToRem(48)
    }
  },
  registryHeroSubtitle: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(72),
    lineHeight: theme.typography.pxToRem(72),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_THIN,
    textTransform: "uppercase",
    color: theme.palette.common.white,
    letterSpacing: theme.typography.pxToRem(-5),
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: theme.typography.pxToRem(60)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: theme.typography.pxToRem(48)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(44),
      lineHeight: theme.typography.pxToRem(44)
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(28)
    }
  }
});

const moTypographyStyles = (theme: Theme) => ({
  ...commonTypography(theme),
  bodyText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12)
  },
  lineItemDisplayName: {
    color: MO_SECONDARY_COLOR,
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300
  },
  lineItemKey: {
    color: "#666",
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(11)
  },
  membershipDialogHeader: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: "#333"
  },
  membershipDialogTypeRegular: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: FONT_BARON_SANS,
    color: "#333"
  },
  strongText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: MO_PRIMARY_COLOR,
    fontWeight: FONT_BOLD
  },
  strongTextToPersonalizationCollapse: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    color: theme.palette.common.black,
    fontWeight: FONT_BOLD,
    textDecoration: "none"
  },
  regularText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_BARON_SANS,
    color: MO_PRIMARY_COLOR
  },
  desktopSiteLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 1.5,
    fontFamily: FONT_BARON_SANS,
    cursor: "pointer",
    color: MO_SECONDARY_COLOR,
    fontWeight: 400,
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(12)
    },
    "&:hover": {
      color: "white"
    }
  },
  skuListColumn1: {
    textTransform: "capitalize",
    color: "#666666"
  },
  skuListColumn2: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.black,
    overflow: "hidden",
    textTransform: "uppercase"
  },
  skuListRegular: {
    textTransform: "initial"
  },
  skuListTotalPrice: {
    textTransform: "initial",
    fontWeight: FONT_MEDIUM,
    color: theme.palette.common.black
  },
  skuListMemberPrice: {
    textTransform: "initial",
    fontWeight: FONT_MEDIUM
  },
  skuListSale: {
    color: "#000000",
    textTransform: "initial",
    fontWeight: FONT_REGULAR
  },
  priceRangeSmall: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 1.5,
    fontFamily: FONT_BARON_SANS,
    cursor: "pointer",
    color: MO_SECONDARY_COLOR,
    fontWeight: 400,
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(12)
    },
    "&:hover": {
      color: "white"
    }
  },
  checkoutAddressTitles: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: "normal",
    color: MO_PRIMARY_COLOR
  },
  desktopCatalogLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 1.5,
    fontFamily: FONT_BARON_SANS,
    cursor: "pointer",
    color: MO_SECONDARY_COLOR,
    fontWeight: 400,
    textTransform: "uppercase",
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(12)
    },
    "&:hover": {
      color: "white"
    }
  },
  desktopNavLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 0.5,
    textTransform: "none",
    cursor: "pointer",
    color: MO_SECONDARY_COLOR,
    transition: "color 200ms",
    "&:hover": {
      color: "white"
    }
  },
  desktopNavLinkHighlight: {
    fontWeight: FONT_MEDIUM
  },
  checkoutSectionHeader: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    color: "#333",
    letterSpacing: ".05em",
    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(3, 0),
      width: "100%",
      fontSize: theme.typography.pxToRem(24),
      textAlign: "center"
    }
  },
  rhBaseCaption2: {
    fontFamily: FONT_BARON_SANS,
    fontSize: "12px",
    color: "#201F1F",
    fontWeight: 400,
    lineHeight: "14.4px",
    letterSpacing: "0.48px",
    paddingLeft: "5px"
  },
  registryHeroTitle: {
    fontSize: theme.typography.pxToRem(48),
    lineHeight: theme.typography.pxToRem(48),
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_THIN,
    color: "#000000",
    letterSpacing: theme.typography.pxToRem(-4),
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(40)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(40)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(40)
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(40)
    }
  },
  registryHeroSubtitle: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(24),
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_THIN,
    textTransform: "uppercase",
    color: "#000000",
    letterSpacing: theme.typography.pxToRem(-2),
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24)
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24)
    }
  },
  registryHeroDescription: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_REGULAR,
    textTransform: "uppercase",
    color: "#000000"
  },
  registryHeroDescriptionSecondary: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: FONT_BOLD,
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: "#000000"
  },
  checkoutLeftSidebarTitle: {
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: "#333",
    fontSize: theme.typography.pxToRem(14)
  },
  lightCatalogNavigation: {}
});

const tnTypographyStyles = (theme: Theme) => ({
  ...commonTypography(theme),
  bodyText: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12)
  },
  membershipDialogHeader: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: FONT_CASLON,
    fontWeight: FONT_BOLD,
    textTransform: "uppercase",
    color: BC_PRIMARY_COLOR
  },
  membershipDialogTypeRegular: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: FONT_ACUMIN_PRO,
    color: BC_PRIMARY_COLOR
  },
  strongText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_CASLON,
    textTransform: "uppercase",
    color: BC_PRIMARY_COLOR,
    fontWeight: FONT_BOLD
  },
  regularText: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: FONT_ACUMIN_PRO,
    color: BC_PRIMARY_COLOR
  },
  checkoutAddressTitles: {
    fontFamily: FONT_ACUMIN_PRO,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: "normal",
    color: BC_PRIMARY_COLOR
  },
  checkoutSectionHeader: {
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "uppercase",
    color: "#333",
    letterSpacing: ".05em",
    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(3, 0),
      width: "100%",
      fontSize: theme.typography.pxToRem(24),
      textAlign: "center"
    }
  },
  checkoutLeftSidebarTitle: {
    fontFamily: FONT_BARON_SANS,
    textTransform: "uppercase",
    color: "#333",
    fontSize: theme.typography.pxToRem(14)
  },
  lightCatalogNavigation: {
    color: "white"
  },
  fullBorder: {
    border: "solid 1px black",
    paddingTop: theme.spacing(1),
    margin: theme.spacing(1)
  }
});

const navigationStyles = (theme: Theme) => ({
  desktopCatalogLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    textDecoration: "none",
    letterSpacing: 1.5,
    color: "inherit",
    cursor: "pointer",
    fontWeight: 400,
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  },
  desktopNavLink: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    letterSpacing: 0.5,
    textTransform: "none",
    cursor: "pointer",
    color: "inherit",
    transition: "color 200ms",
    "&.terminal:hover": {
      color: "inherit",
      opacity: 0.85,
      backgroundColor: "transparent"
    }
  },
  desktopNavLinkHighlight: {
    color: "inherit",
    fontWeight: FONT_MEDIUM,
    "&:hover": {
      color: "inherit",
      opacity: 0.85
    }
  }
});

function generateStyles<T extends string, K extends { [key: string]: any }>({
  stylesObj,
  keys
}: {
  stylesObj: (theme: Theme) => K;
  keys: T[];
}) {
  return makeStyles((theme: Theme) => {
    const obj = stylesObj(theme);
    const styles = keys.reduce((acc, next) => {
      return {
        ...acc,
        [next]: obj[next] || {}
      };
    }, {}) as Record<T, K>;
    return createStyles(styles);
  });
}
export const useNavigationStylesByBrand = ({ keys }: { keys: string[] }) => {
  return generateStyles({ keys, stylesObj: navigationStyles })();
};

export function useTypographyStylesByBrand<T extends string>({
  keys
}: {
  keys: T[];
}) {
  const brand = useRHBrand();
  switch (brand) {
    case "MO":
      return generateStyles({ keys, stylesObj: moTypographyStyles })();
    case "BC":
      return generateStyles({ keys, stylesObj: bcTypographyStyles })();
    case "TN":
      return generateStyles({ keys, stylesObj: tnTypographyStyles })();
    case "SH":
      return generateStyles({ keys, stylesObj: typographyStyles })();
    case "BH":
      return generateStyles({ keys, stylesObj: typographyStyles })();
    default:
      return generateStyles({ keys, stylesObj: typographyStyles })();
  }
}

export { useTypographyStylesByBrand as useTypographyStyles };
export default useTypographyStylesByBrand;
