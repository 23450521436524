import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import classNames from "classnames";
import { StringToJSX } from "utils/stringToJsx";

export type RHRLogoIconProps = SvgIconProps & {
  icon: string;
  viewBox?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export const RHRLogoIcon: FC<RHRLogoIconProps> = ({
  icon,
  viewBox,
  fill,
  width,
  height,
  ...props
}) => {
  return (
    <>
      <SvgIcon
        viewBox={viewBox ? viewBox : "0 0 60 57"}
        {...props}
        style={{
          fill: fill || undefined,
          width: width || undefined,
          height: height || undefined
        }}
        className={classNames("fill-black", props.className)}
      >
        <StringToJSX domString={icon} />
      </SvgIcon>
    </>
  );
};

export default RHRLogoIcon;
